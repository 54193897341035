import React, { useState } from "react";
import Form from "./Form";

const ThankYouState = () => {
  return (
    <div className="row d-flex h-100 align-items-center justify-content-center">
      <div className="col-8">
        <div className="success-message">
          <div className="pulse-icon">
            <div className="circle circle-1"></div>
            <div className="circle circle-2"></div>
            <div className="circle circle-3"></div>
          </div>
          <h2 className="text-center">
            Nachricht wurde gesendet!
            <br />
            Wir melden uns schnellstmöglich bei dir.
          </h2>
        </div>
      </div>
    </div>
  );
};

const ContactForm = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("initial");
  const [errors, setErrors] = useState([]);

  const submitForm = async (e) => {
    e.preventDefault();
    setErrors([]);
    setStatus("submitting");
    const body = {
      fields: [
        {
          name: "name",
          value: values.name,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "message",
          value: values.message,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: "I agree to allow Njiuko GmbH to store and process my personal data.",
        },
      },
      skipValidation: true,
    };
    const response = await fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/4247917/94a4d653-e5bc-4954-80fe-799ab85c8770",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    const data = response.json();

    if (!data.errors || data.errors.length === 0) {
      setStatus("submitted");
    } else {
      setStatus("initial");
      setErrors(data.errors);
    }
  };

  const isSubmitting = () => status === "submitting";
  const isSubmitted = () => status === "submitted";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const renderErrors = () => {
    return errors.map(([key, value]) => {
      return (
        <div>
          {key}: {value}
        </div>
      );
    });
  };

  return isSubmitted() ? (
    <ThankYouState />
  ) : (
    <React.Fragment>
      {errors.length > 0 && renderErrors()}
      <Form
        onChange={handleInputChange}
        values={values}
        onSubmit={submitForm}
        submitting={isSubmitting()}
      />
    </React.Fragment>
  );
};

export default ContactForm;
