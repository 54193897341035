import React from "react";

const Form = (props) => {
  const { onChange, onSubmit, values, submitting } = props;

  return (
    <React.Fragment>
      <h1>Hey there, nice to meet you.</h1>
      <p className="paragraph">
        Du hast Fragen oder Interesse an einer Zusammenarbeit? Schreib uns hier oder schick eine E-Mail an <a className="gradient-underline" href="mailto:hello@njiuko.com">
        hello@njiuko.com
      </a>!
      </p>
      <form onSubmit={onSubmit}>
        <div className="form-row">
          <label htmlFor="contact-name">
            <input
              type="text"
              id="contact-name"
              name="name"
              disabled={submitting}
              placeholder="Name"
              onChange={onChange}
              value={values.name}
              required
            />
            <span className="label-text">Name</span>
            <div className="invalid-feedback">
              Bitte nenne uns deinen Vor- und Nachnamen
            </div>
          </label>
        </div>
        <div className="form-row">
          <label htmlFor="contact-email">
            <input
              type="text"
              id="contact-email"
              name="email"
              placeholder="E-Mail"
              disabled={submitting}
              required
              onChange={onChange}
              value={values.email}
            />
            <span className="label-text">E-Mail</span>
            <div className="invalid-feedback">Bitte gib deine E-Mail an</div>
          </label>
        </div>
        <div className="form-row">
          <label htmlFor="contact-message">
            <textarea
              id="contact-message"
              className="js-auto-grow"
              name="message"
              placeholder="Nachricht"
              disabled={submitting}
              onChange={onChange}
              value={values.message}
              required
            ></textarea>
            <span className="label-text">Nachricht</span>
            <div className="invalid-feedback">Bitte gib eine Nachricht ein</div>
          </label>
        </div>

        <div className="form-row text-right">
          <input
            type="submit"
            value="Abschicken"
            className="contact-submit btn btn-primary pull-right"
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default Form;
